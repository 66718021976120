import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { PageButton, SubmitButton } from "../components/UI/Buttons"
import ShareBanner from "../components/UI/share-banner"
import NewsLetterForm from "../components/UI/newsletter-form"

const ListItem = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  height: 350px;
  margin-bottom: 10vh;
  filter: var(--tuva-drop-shadow-filter);

  .content-box {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 1em 3em;
    padding-top: 0;
  }

  .ink {
    margin: 1.5em 0;
  }

  @media (max-width: 768px) {
    min-height: 350px;
    height: unset;
    grid-template-columns: 100%;

    .content-box {
      padding: 1em 2em;
    }

    .image {
      height: 200px;
    }

    .ink {
      margin: 2em auto;
    }

    h3 {
      font-size: 1.75em;
    }
  }
`

const Insights = ({ data }) => {
  const blogs = data.allMarkdownRemark.edges
  const [list, setList] = useState(7)
  const [endOfList, setEndOfList] = useState(false)

  //Sort the array so that it's in date order.
  function compare(a, b) {
    var dateA = new Date(a.node.frontmatter.date).getTime()
    var dateB = new Date(b.node.frontmatter.date).getTime()

    if (dateA < dateB) return 1
    if (dateA > dateB) return -1
    return 0
  }

  //Sort the array so that if something is 'pinned' it goes straight to the top.
  function pin(a) {
    if (a.node.frontmatter.pin) return -1
    return 0
  }

  const listAdd = e => {
    if (list < blogs.length) setList(list + 1)
    else {
      setEndOfList(true)
    }
  }

  blogs.sort(compare).sort(pin)

  const ListElement = blogs.slice(0, list).map(({ node }) => {
    const { image, title, type } = node.frontmatter
    const excerpt = node.excerpt
    const slug = node.fields.slug

    // Create list of case studies from CMS. Order by date.
    return (
      <ListItem key={slug}>
        <div className="content-box">
          <h1 className="type">{type}</h1>
          <h3>{title}</h3>

          <div dangerouslySetInnerHTML={{ __html: excerpt }} />
          <PageButton styleName="ink" linkRef={slug}>
            Find Out More
          </PageButton>
        </div>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={slug}
          className="image"
        />
      </ListItem>
    )
  })

  return (
    <Layout>
      <div>{ListElement}</div>
      <div className="call-to-action">
        {endOfList ? (
          <h3 style={{ paddingRight: "1em", margin: "0" }}>
            This is all of our current insights.
          </h3>
        ) : null}
        <SubmitButton click={listAdd} disabled={endOfList}>
          &nbsp;more...&nbsp;
        </SubmitButton>
      </div>
      <NewsLetterForm />
      <ShareBanner />
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { cms: { eq: "insight" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          excerpt
          frontmatter {
            cms
            date
            color
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [PNG, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
            pin
            title
            type
          }
        }
      }
    }
  }
`

export default Insights
